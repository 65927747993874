import { PersonalInformation } from "@/Manager/Users/UserManager"
import { PartialExcept } from "@/Types/helpers/partialExcept"
import { getMePath } from "."

export const updatePersonalInformation = async (
  props: PartialExcept<PersonalInformation["data"], "email">,
) => {
  const result = await fetch(getMePath(), {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...props,
      type: "personal",
    }),
  })

  return await result.json()
}
