import { PERMISSIONS } from "@/Core/Permission/role"
import useUserCanAccess from "../../../hooks/user/useUserCanAccess"
import Page404 from "../../../pages/404"
import { System } from "@design-system/index"

function SecurityApplication({ children, render404 = false }) {
  const { canAccess, status } = useUserCanAccess({
    permission: PERMISSIONS.APPLICATION,
    access: "view",
  })

  if (status === "loading") {
    return <System.SuspenseFallback.Security />
  }

  if (!canAccess) {
    if (!render404) {
      return null
    }

    return <Page404 />
  }

  return <>{children}</>
}

export default SecurityApplication
