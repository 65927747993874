import { getSignedUrlPath } from "."

interface Props {
  filename: string
  type: "user"
}

export const getSignedUrl = async (props: Props) => {
  const result = await fetch(getSignedUrlPath(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(props),
  })

  return await result.json()
}
