import { flattenDeep, get, isNil } from "lodash"
// @ts-ignore
import { RBAC } from "rbac"
import { PERMISSIONS, PERMISSIONS_ACCESS, PERMISSION_KEY, ROLES } from "../role"

interface Props {
  permission: PERMISSIONS
  access: PERMISSION_KEY
  user: {
    role: string
    grants: Array<string>
    rbac_grants: Array<string>
  }
}

export const userCanAccessByRbac = async ({
  user,
  permission,
  access,
}: Props) => {
  const role = get(user, "role", ROLES.USER)

  let initData = {
    roles: [role],
    permissions: PERMISSIONS_ACCESS,
    grants: {
      [role]: get(user, "grants", []),
    },
  }

  if (role !== ROLES.USER) {
    let rbacGrants = get(user, "rbac_grants", {})
    if (isNil(rbacGrants)) {
      rbacGrants = []
    }

    const grants = flattenDeep(Object.values(rbacGrants)).filter(
      (item) => item !== null,
    )

    // Manage Legacy grants for old users
    initData = {
      roles: [role, ROLES.USER],
      permissions: PERMISSIONS_ACCESS,
      grants: {
        [role]: [...grants],
        [ROLES.USER]: ["view_application"],
      },
    }
  }

  const rbac = new RBAC(initData)

  await rbac.init()

  return await rbac.can(role, access, permission)
}
