import { useEffect, useState } from "react"

import { useSession } from "next-auth/react"
import { userCanAccessByRbac } from "@/Core/Permission/helpers/userCanAccessByRbac"
import { PERMISSIONS, PERMISSION_KEY } from "@/Core/Permission/role"
import { get } from "lodash"

interface Props {
  permission: string
  access: string
}

const useUserCanAccess = ({ permission, access }: Props) => {
  const { data: session, status } = useSession()
  const [canAccess, setCanAccess] = useState(true)

  if (status === "unauthenticated" && canAccess) {
    setCanAccess(false)
  }

  useEffect(() => {
    if (status === "loading") {
      return
    }

    const getCanAccess = async () => {
      const can = await userCanAccessByRbac({
        user: {
          //@ts-ignore
          rbac_grants: get(session, "user.rbac_grants", {}),
          //@ts-ignore
          grants: session?.user?.grants,
          //@ts-ignore
          role: session?.user?.role,
        },
        access: access as PERMISSION_KEY,
        permission: permission as PERMISSIONS,
      })

      setCanAccess(can)
    }

    getCanAccess()
  }, [status, session])

  return { canAccess: true, status }
}

export default useUserCanAccess
