import { useEffect } from "react"
import Router from "next/router"
import { useSession } from "next-auth/react"

interface Props {
  redirectTo?: string
  redirectIfFound?: boolean
}

export default function useTokenInit({
  redirectTo = null,
  redirectIfFound = false,
}: Props) {
  const { status } = useSession()

  useEffect(() => {
    if (status === "loading") {
      return
    }

    if (status !== "authenticated" && redirectTo && !redirectIfFound) {
      Router.push(redirectTo)
    }

    // If redirectIfFound is also set, redirect if the user was found
    if (redirectIfFound && status === "authenticated") {
      Router.push(redirectTo)
    }
  }, [status, redirectIfFound, redirectTo])
}
