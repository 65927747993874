import { getMeNotificationsPath } from "."

export const updateReadNotifications = async () => {
  const result = await fetch(getMeNotificationsPath(), {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  })

  return await result.json()
}
