import { updatePassword } from "./updatePassword"
import { updateTwoFactorAuthentification } from "./updateTwoFactorAuthentification"
import { updatePersonalInformation } from "./updatePersonalInformation"
import { updateReadNotifications } from "./updateReadNotifications"
import { getSignedUrl } from "./getSignedUrl"

export const getMePath = () => `/api/me`

export const getMeNotificationsPath = () => `${getMePath()}/notifications`

export const getSignedUrlPath = () => `${getMePath()}/get-signed-url`

const FetchApiMe = {
  updatePassword,
  updateTwoFactorAuthentification,
  updateReadNotifications,
  updatePersonalInformation,
  getSignedUrl,
}

export default FetchApiMe
